import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import website from "../../config/website"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import {
  Flex,
  ImgHover,
  ImgStyled,
  StyledLink,
  StyledA,
  TitleHover,
} from "../elements"

const GridWithHero = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: 5px 5px; 
  margin-top: 18px;
  
  ${props => props.theme.mediaQueries.sm} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${props => props.theme.mediaQueries.md} {
    grid-template-columns: repeat(5, 1fr);
  }
  ${props => props.theme.mediaQueries.lg} {
    grid-template-columns: repeat(6, 1fr);
  }
  
  ${props => props.theme.mediaQueries.xl} {
    grid-template-columns: repeat(auto-fit, minmax(100px, 300px));
    justify-content: center;    
  }

  &:before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  & > *:first-child {
    grid-gap: 0; 
    grid-row: 1 / span 2;
    grid-column: 1 / span 2;
  }
`;

const Domain = ({
  data: { prismicDomain: domain },
  location,
  pageContext: { allUpperDomains, breadcrumb },
}) => {  
  const desc = domain.data.meta_description || domain.data.title.text
  const title = domain.data.meta_title || domain.data.title.text
  
  // breadcrumb.crumbs[breadcrumb.crumbs.length - 1].crumbLabel = domain.title[0].text;
  return (
    <Layout
      customSEO
      location={location}
      allUpperDomains={allUpperDomains}
      breadcrumb={breadcrumb}
    >
      <SEO
        title={`${title} | ${website.titleAlt}`}
        pathname={location.pathname}
        desc={desc}
        node={domain}
      />
      <GridWithHero>
        {domain.data.list_item.map(
          ({ media, title_item, image }, index) => {
            const isRolloverImage = media.document?.data?.image_mosaic_rollover ? true : false
            if (media.document?.data.image_mosaic)
              return (
                <StyledLink
                  to={`${location.pathname}/${media.document.uid}`}
                  key={`${index}-img`}
                  css="position:relative;"
                  isRolloverImage={isRolloverImage}
                >
                  <ImgStyled
                    fluid={media.document.data.image_mosaic.fluid}
                    height="100%"
                    width="100%"
                    alt={media.document.data.image_mosaic.alt}
                  />
                  {isRolloverImage && (
                    <ImgHover
                      fluid={media.document.data.image_mosaic_rollover.fluid}
                      height="100%"
                      width="100%"
                      alt={media.document.data.image_mosaic_rollover.alt}
                    />
                  )}
                  {!isRolloverImage && (
                    <TitleHover>{media.document.data.title.text}</TitleHover>
                  )}
                </StyledLink>
              )
            if (media.link_type === "Web") {
              return (
                <StyledA
                  href={media.url}
                  key={`${index}-img`}
                  css="position:relative;"
                  target="_blank"
                >
                  <Img
                    fluid={image.fluid}
                    height="100%"
                    width="100%"
                    alt={image.alt}
                  />
                  <TitleHover>{title_item.text}</TitleHover>
                </StyledA>
              )
            }
            if (media.link_type === "Media") {
              return (
                <StyledA
                  href={media.url}
                  key={`${index}-img`}
                  css="position:relative;"
                  target="_blank"
                >
                  <Img                    
                    fluid={image.fluid}
                    height="100%"
                    width="100%"
                    alt={image.alt}
                  />
                  <TitleHover>{title_item.text}</TitleHover>
                </StyledA>
              )
            }
            if (media.document?.data?.title)
              return (
                <Flex
                  bg="white"
                  color="black"
                  alignItems="center"
                  justifyContent="center"
                  key={media.document.uid}
                >
                  <Link to={`${location.pathname}/${media.document.uid}`}>
                    {media.document.data.title.text}
                  </Link>
                </Flex>
              )

              return null;
          }
        )}
      </GridWithHero>
    </Layout>
  )
}

export default Domain

Domain.propTypes = {
  data: PropTypes.shape({
    prismicDomain: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
} 

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query DomainBySlug($uid: String!) {
    prismicDomain(uid: {eq: $uid}) {    
      uid
      data{
        meta_description
        meta_title
        title {
          text
        }
        list_item {
          image{
            url
            alt
            fluid(maxWidth: 600, maxHeight: 600) {
                ...GatsbyPrismicImageFluid
            }
          }                
          title_item {
            text
          }
          media {
            link_type
            __typename
            url
            target
            document {
              __typename
              ... on PrismicProject {
                uid
                data {
                  title {
                    text
                  }
                  image_mosaic{
                    url
                    alt
                    fluid(maxWidth: 600, maxHeight: 600) {
                        ...GatsbyPrismicImageFluid
                    }
                  }              
                  
                }
              }
              ... on PrismicDomain {
                uid
                data {
                  title {
                    text
                  }
                  image_mosaic{
                    url
                    alt
                    fluid(maxWidth: 600, maxHeight: 600) {
                        ...GatsbyPrismicImageFluid
                    }
                  }                
                  image_mosaic_rollover{
                    url
                    alt
                    fluid(maxWidth: 600, maxHeight: 600) {
                        ...GatsbyPrismicImageFluid
                    }
                  }                
                }
              }
            }
          }
        }
      }      
      
    }
  }
`
